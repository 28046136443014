import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import { getOrgane } from "../../API/Organe/OrgChartBox"; // API function to get the data

const StyledNode = styled.div`
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid #007bff;
  background-color: ${props => props.bgColor || '#f8f9fa'}; /* Use bgColor prop */
  color: #343a40;
  text-align: center; /* Center-align text if needed */
`;

const Name = styled.div`
  font-weight: bold; /* Bold for names */
`;

const Role = styled.div`
  font-size: 0.9em; /* Smaller font size for roles */
  color: #6c757d; /* Optional: lighter color for roles */
`;

// Define colors for each role
const roleColors = {
  "Président": "#d1e7dd", // Light green
  "Vice-président": "#cfe2ff", // Light blue
  "Sécrétaire": "#fff3cd", // Light yellow
  "Trésorier": "#f8d7da", // Light red
  "Président sortant": "#e2e3e5", // Light grey
};

const OrgChart = () => {
  const [teamMembers, setTeamMembers] = useState([]); // State to store team members
  const [loading, setLoading] = useState(true); // State to track loading

  // Fetch team members when the component mounts
  useEffect(() => {
    getOrgane()
      .then(response => {
        setTeamMembers(response.data.info); // Assuming the API response structure is correct
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch(error => {
        console.error("Error fetching team members:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching data
  }

  // Assuming roles are dynamically fetched and ordered accordingly
  const president = teamMembers.find(member => member.role === "Président");
  const vicePresident = teamMembers.find(member => member.role === "Vice-président");
  const secretary = teamMembers.find(member => member.role === "Sécrétaire");
  const treasurer = teamMembers.find(member => member.role === "Trésorier");
  const pastPresident = teamMembers.find(member => member.role === "Président sortant");

  return (
    <Tree lineWidth={'2px'} lineColor={'#007bff'} lineBorderRadius={'10px'}>
      {/* Ensure the President is at the top */}
      <TreeNode label={
        <StyledNode bgColor={roleColors["Président"]}>
          <Name>{president?.name || 'N/A'}</Name>
          <Role>{president?.role || 'Président'}</Role>
        </StyledNode>
      }>
        {/* Vice-president, Secretary, and Treasurer under President */}
        <TreeNode label={
          <StyledNode bgColor={roleColors["Vice-président"]}>
            <Name>{vicePresident?.name || 'N/A'}</Name>
            <Role>{vicePresident?.role || 'Vice-président'}</Role>
          </StyledNode>
        }>
          <TreeNode label={
            <StyledNode bgColor={roleColors["Sécrétaire"]}>
              <Name>{secretary?.name || 'N/A'}</Name>
              <Role>{secretary?.role || 'Sécrétaire'}</Role>
            </StyledNode>
          } />
          <TreeNode label={
            <StyledNode bgColor={roleColors["Trésorier"]}>
              <Name>{treasurer?.name || 'N/A'}</Name>
              <Role>{treasurer?.role || 'Trésorier'}</Role>
            </StyledNode>
          } />
        </TreeNode>
        {/* Past President on the same level */}
        <TreeNode label={
          <StyledNode bgColor={roleColors["Président sortant"]}>
            <Name>{pastPresident?.name || 'N/A'}</Name>
            <Role>{pastPresident?.role || 'Président sortant'}</Role>
          </StyledNode>
        } />
      </TreeNode>
    </Tree>
  );
};

export default OrgChart;
